import React, { useEffect, useRef, useState } from "react";
import EffieLogo from "../assets/effie-logo.svg";
import { RetellWebClient } from "retell-client-js-sdk";
import axios from "axios";

interface EntryObject {
  role: "agent" | "user";
  content: string;
  words?: string[];
}

const RetellCallManager: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isCallActive, setIsCallActive] = useState<boolean>(false);
  const [conversation, setConversation] = useState<EntryObject[]>([]);
  const retellWebClient = useRef<RetellWebClient | null>(null);
  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [conversation]);

  useEffect(() => {
    retellWebClient.current = new RetellWebClient();

    retellWebClient.current.on("call_started", () => {
      setIsCallActive(true);
    });
    retellWebClient.current.on("call_ended", () => {
      setIsCallActive(false);
    });
    retellWebClient.current.on("agent_start_talking", () => {
      console.log("agent_start_talking");
    });
    retellWebClient.current.on("agent_stop_talking", async () => {
      console.log("agent_stop_talking");
    });
    retellWebClient.current.on("audio", (audio: Float32Array) => {
      console.log("retellWebClient audio", audio);
    });
    retellWebClient.current.on("update", (update) => {
      console.log("Update:", update);
      const newEntries = update.transcript.map((entry: any) => {
        return { role: entry.role, content: entry.content };
      });
      console.log(newEntries, "newbie");
      setConversation(newEntries);
    });
    retellWebClient.current.on("error", (error: any) => {
      console.error("An error occurred:", error);
      retellWebClient.current?.stopCall();
    });

    return () => {
      retellWebClient.current?.stopCall();
      retellWebClient.current = null;
    };
  }, []);

  const handleStartCall = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        "https://api.retellai.com/v2/create-web-call",
        {
          agent_id: "72b85eec0b9d8bbf21af9f3c1aea2ea5",
        },
        {
          headers: {
            Authorization: "Bearer babc3851-277e-4a05-96d7-57828dca86d2",
          },
        }
      );
      retellWebClient.current?.startCall({
        accessToken: response.data.access_token,
      });
    } catch (error) {
      console.error("Error making API call:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleStopCall = () => {
    try {
      retellWebClient.current?.stopCall();
    } catch (error) {
      console.error("Error stopping call:", error);
    }
  };

  return (
    <div className="containerWrap h-screen flex flex-col overflow-hidden pt-14 md:pt-20">
      {isLoading ? (
        <div className="flex items-center justify-center h-full">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
        </div>
      ) : (
        <div className="scrollable overflow-y-auto" ref={scrollRef}>
          <div className="bg-teal-100 w-full fixed top-0 px-5">
            <div className="flex justify-between items-center">
              <img
                src="/logo.svg"
                alt="DataSpeckle Logo"
                className="dark:invert w-20 h-16"
              />
              <div>
                <button
                  className="button-start md:w-12 md:h-12 w-10 h-10 rounded-full ml-5 text-sm bg-white"
                  onClick={handleStartCall}
                ></button>
                <button
                  className="button-stop md:w-12 md:h-12 w-10 h-10 rounded-full ml-3 text-sm bg-white"
                  onClick={handleStopCall}
                ></button>
              </div>
            </div>
          </div>
          <div ref={scrollRef}>
            {conversation.map((log: EntryObject, index: number) => (
              <div key={index} className={`message ${log.role}`}>
                {log.role === "agent" ? (
                  <div className="flex justify-start px-4 mt-5">
                    <div className="flex space-x-1">
                      <img src={EffieLogo} width={40} />
                      <label
                        className="bg-gray-100 p-2 rounded-r-lg"
                        style={{ maxWidth: "80%" }}
                      >
                        {log.content}
                      </label>
                    </div>
                  </div>
                ) : (
                  <div className="flex justify-end px-4 mt-5">
                    <label
                      className="bg-teal-100 p-2 rounded-l-lg"
                      style={{ maxWidth: "80%" }}
                    >
                      {log.content}
                    </label>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default RetellCallManager;
