import axios, { AxiosResponse } from "axios";
import { commonServerUrl } from "./configs";

interface TranscriptData {
  patient_conversation_id: string;
  client_code: string;
  domain_name: string;
  history: { role: string; content: string }[];
}

export const diseasePrediction = async (
  transcriptText: string
): Promise<AxiosResponse<any>> => {
  const body: TranscriptData = {
    patient_conversation_id: "1",
    client_code: "code",
    domain_name: "diosol",
    history: [
      {
        role: "user",
        content: transcriptText,
      },
    ],
  };

  try {
    const data = await axios.post(
      `${commonServerUrl}/database/notes_disease_prediction`,
      body
    );
    return data;
  } catch (error) {
    console.error("Error in diseasePrediction:", error);
    throw error;
  }
};

export const medicalEntities = async (
  transcriptText: string
): Promise<AxiosResponse<any>> => {
  const body: TranscriptData = {
    patient_conversation_id: "1",
    client_code: "code",
    domain_name: "diosol",
    history: [
      {
        role: "user",
        content: transcriptText,
      },
    ],
  };

  try {
    const data = await axios.post(
      `${commonServerUrl}/notes_medical_entities`,
      body
    );
    return data;
  } catch (error) {
    console.error("Error in medicalEntities:", error);
    throw error;
  }
};

export const sendNotesInMail = async (
  body: object
): Promise<AxiosResponse<any>> => {
  const accessToken = localStorage.getItem("accessToken");

  try {
    const data = await axios.post(
      "/conference/notetakers",
      body,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return data;
  } catch (error) {
    console.error("Error in sendNotesInMail:", error);
    throw error;
  }
};
