import { useState, useEffect, useRef } from "react";
import { diseasePrediction, medicalEntities } from "../utils/api";

interface KeyPoint {
  entity_group: string;
  word: string;
}

interface FooterProps {
  transcript: { text?: string; blob?: Blob };
  startRecording: () => void;
  stopRecording: () => void;
  pauseRecording: () => void;
  setFinishRecording: React.Dispatch<React.SetStateAction<boolean>>;
  setDiagnosis: React.Dispatch<React.SetStateAction<string[]>>;
  setKeyPoints: React.Dispatch<React.SetStateAction<KeyPoint[]>>;
  handleStartLoading: () => void;
  handleStopLoading: () => void;
}

const Footer: React.FC<FooterProps> = ({
  transcript,
  startRecording,
  stopRecording,
  pauseRecording,
  setDiagnosis,
  setKeyPoints,
  setFinishRecording,
  handleStartLoading,
  handleStopLoading,
}) => {
  const [beginRecording, setBeginRecording] = useState<boolean>(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
    }
  }, [transcript.text]);

  const handleStartRecording = () => {
    setBeginRecording(true);
    startRecording();
  };

  const handlePauseRecording = () => {
    setBeginRecording(false);
    pauseRecording();
  };

  const handleStopRecording = async () => {
    setBeginRecording(false);
    setFinishRecording(true);
    stopRecording();
    handleStartLoading();

    if (transcript && transcript.text) {
      try {
        const [diseasePredictionResponse, medicalEntitiesResponse] =
          await Promise.all([
            diseasePrediction(transcript.text),
            medicalEntities(transcript.text),
          ]);
        setDiagnosis(diseasePredictionResponse.data);
        setKeyPoints(medicalEntitiesResponse.data);
      } catch (error) {
        console.error("Error:", error);
      } finally {
        handleStopLoading();
      }
    } else {
      console.error("Transcript text is undefined.");
      handleStopLoading();
    }
  };

  return (
    <div className="bg-teal-100 w-full fixed bottom-0 px-2 md:px-10 shadow-top">
      <form className="py-8 containerWrap flex">
        <textarea
          readOnly
          ref={textareaRef}
          className="border border-teal-200 bg-teal-50 rounded-lg p-2 w-full h-14 md:h-15 resize-none overflow-y-auto focus:outline-none text-teal-950"
          value={transcript.text}
          placeholder="Start Recording"
        />

        <div className="flex items-center">
          {!beginRecording ? (
            <button
              type="button"
              onClick={handleStartRecording}
              className="button-start md:w-12 md:h-12 w-10 h-10 flex justify-center items-center rounded-full ml-5 text-sm bg-white"
            ></button>
          ) : (
            <button
              type="button"
              onClick={handlePauseRecording}
              className="button-pause md:w-12 md:h-12 w-10 h-10 flex justify-center items-center rounded-full ml-3 text-sm bg-white"
            ></button>
          )}
          <button
            type="button"
            onClick={handleStopRecording}
            className="button-stop md:w-12 md:h-12 w-10 h-10 flex justify-center items-center rounded-full ml-3 text-sm bg-white"
          ></button>
        </div>
      </form>
    </div>
  );
};

export default Footer;
