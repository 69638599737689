import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import LiveAudioChat from "../assets/live-chat.svg";

const NavBar = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  const handleLiveChat = () => {
    navigate("/retellCallManager");
  };

  return (
    <>
      <div className="bg-teal-100 w-full fixed top-0 px-5">
        <div className="flex justify-between items-center">
          <img
            src="/logo.svg"
            alt="DataSpeckle Logo"
            className="dark:invert w-20 h-16"
          />
          <div className="flex">
            <button
              className="bg-teal-100 rounded-full"
              onClick={handleLiveChat}
            >
              <img src={LiveAudioChat} />
            </button>
            <button
              className="text-center bg-white text-gray-800 font-semibold py-0.5 px-4 ml-5 border border-gray-50 rounded hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
              onClick={handleLogout}
            >
              Logout
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default NavBar;
