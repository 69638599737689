import React, { useEffect, useRef, useState } from "react";
import { sendNotesInMail } from "../utils/api";
import { toast } from "react-toastify";

interface KeyPoint {
  entity_group: string;
  word: string;
}

interface MainContentProps {
  finishRecording: boolean;
  transcript: { text?: string; blob?: Blob };
  keyPoints: KeyPoint[];
  diagnosis: string[];
  isLoading: boolean;
}

const MainContent: React.FC<MainContentProps> = ({
  finishRecording,
  transcript,
  keyPoints,
  diagnosis,
  isLoading,
}) => {
  const [mailToggle, setMailToggle] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const scrollRef = useRef<HTMLDivElement>(null);

  const renderTranscriptWithHighlights = () => {
    if (!transcript || !transcript.text) return null;

    let highlightedText = transcript.text;

    keyPoints.forEach((item) => {
      const regex = new RegExp(`\\b${item.word}\\b`, "gi");
      highlightedText = highlightedText.replace(
        regex,
        `<span class="bg-yellow-300 px-2.5 py-0.5 rounded-full">${item.word}</span>`
      );
    });

    return (
      <p
        className="text-sm text-teal-950"
        dangerouslySetInnerHTML={{ __html: highlightedText }}
      />
    );
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleMailSentInNotes = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    const diagnosisString = diagnosis.join(", ");
    const keyPointsString = keyPoints.map((points) => points.word).join(", ");

    const body = {
      email,
      transcription: transcript.text,
      diagnostics: diagnosisString,
      keywords: keyPointsString,
    };

    if (transcript?.text) {
      try {
        const response = await sendNotesInMail(body);
        if (response?.data?.id) {
          toast.success("Email sent successfully!");
        }
      } catch (error) {
        console.error("Error sending notes in mail:", error);
      }
    } else {
      console.error("Transcript text is undefined");
    }
  };

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [transcript]);

  return (
    <div className="containerWrap bg-teal-50 h-screen flex flex-col justify-end pb-24 overflow-hidden pt-14 md:pt-20">
      {isLoading ? (
        <div className="flex items-center justify-center h-full">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
        </div>
      ) : (
        finishRecording &&
        transcript &&
        transcript.text &&
        transcript.text.length > 0 && (
          <div ref={scrollRef} className="overflow-y-auto flex-1">
            <div className="flex flex-row items-end justify-end">
              <div className="pb-2 pt-2 pl-4 pr-4 rounded-lg bg-teal-100 inline-block m-5">
                {renderTranscriptWithHighlights()}
              </div>
            </div>
            {diagnosis.length > 0 && (
              <>
                <label className="font-bold text-teal-950 flex ml-6">
                  PREDICTED DIAGNOSTICS:{" "}
                </label>
                <div className="flex items-end justify-start mx-5 flex-wrap">
                  {diagnosis.map((item, index) => (
                    <div
                      key={index}
                      className="pb-2 pt-2 pl-4 pr-4 rounded-lg bg-teal-600 inline-block m-1"
                    >
                      <p className="text-sm text-white text-left font-bold">
                        {item.toUpperCase()}
                      </p>
                    </div>
                  ))}
                </div>
                {mailToggle ? (
                  <form
                    onSubmit={handleMailSentInNotes}
                    className="mx-5 mb-10 mt-5"
                  >
                    <input
                      required
                      type="email"
                      className="border border-teal-700 bg-teal-50 rounded-lg p-2 w-full focus:outline-none text-teal-950"
                      placeholder="Email"
                      value={email}
                      onChange={handleEmailChange}
                    />
                    <div className="flex justify-end">
                      <button
                        type="submit"
                        className="bg-teal-600 text-white font-semibold py-2 px-4 rounded mt-5"
                      >
                        SEND
                      </button>
                    </div>
                  </form>
                ) : (
                  <div className="mt-2 mb-16 mx-6 flex flex-start">
                    <button
                      onClick={() => setMailToggle(true)}
                      className="bg-white text-gray-800 font-semibold py-2 px-4 border border-gray-50 rounded shadow-md hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
                    >
                      Send in through mail?
                    </button>
                  </div>
                )}
              </>
            )}
          </div>
        )
      )}
    </div>
  );
};

export default MainContent;
