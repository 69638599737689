import { Route, Routes } from "react-router-dom";
import "./App.css";
import Login from "./pages/login";
import Notes from "./pages/notes";
import RequireAuth from "./auth/RequireAuth";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RetellCallManager from "./pages/retellCallManager";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          path="/notes"
          element={
            <RequireAuth>
              <Notes />
            </RequireAuth>
          }
        />
        <Route
          path="/retellCallManager"
          element={
            <RequireAuth>
              <RetellCallManager />
            </RequireAuth>
          }
        />
      </Routes>
      <ToastContainer />
    </>
  );
}

export default App;
