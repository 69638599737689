import React, { useState } from "react";
import NavBar from "../components/navBar";
import MainContent from "../components/mainContent";
import Footer from "../components/footor";
import { useWhisper } from "../hook";

interface KeyPoint {
  entity_group: string;
  word: string;
}

const Notes: React.FC = () => {
  const { transcript, pauseRecording, startRecording, stopRecording } =
    useWhisper({
      streaming: true,
      timeSlice: 1_000,
      whisperConfig: {
        language: "en",
      },
      apiKey: "your-api-key-here",
    });

  const [diagnosis, setDiagnosis] = useState<string[]>([]);
  const [keyPoints, setKeyPoints] = useState<KeyPoint[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [finishRecording, setFinishRecording] = useState<boolean>(false);

  const handleStartLoading = () => setIsLoading(true);
  const handleStopLoading = () => setIsLoading(false);

  return (
    <>
      <NavBar />
      <MainContent
        isLoading={isLoading}
        finishRecording={finishRecording}
        transcript={transcript}
        keyPoints={keyPoints}
        diagnosis={diagnosis}
      />
      <Footer
        transcript={transcript}
        startRecording={startRecording}
        stopRecording={stopRecording}
        pauseRecording={pauseRecording}
        setDiagnosis={setDiagnosis}
        setKeyPoints={setKeyPoints}
        setFinishRecording={setFinishRecording}
        handleStartLoading={handleStartLoading}
        handleStopLoading={handleStopLoading}
      />
    </>
  );
};

export default Notes;
